@font-face {
  font-family: "Glyphicons Regular";
  src: url("./assets/fonts/glyphicons-regular.eot");
  src: url("./assets/fonts/glyphicons-regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/glyphicons-regular.woff2") format("woff2"),
    url("./assets/fonts/glyphicons-regular.woff") format("woff"),
    url("./assets/fonts/glyphicons-regular.ttf") format("truetype"),
    url("./assets/fonts/glyphicons-regular.svg#glyphiconsregular") format("svg");
}
.switch-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.switch-wrapper > div {
  width: 100%;
  height: 100%;
  position: absolute;
}
.body {
  font-family: aaux-next, sans-serif;
}
html,
body {
  overflow: hidden;
}
